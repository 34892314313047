<template>
  <div class="wrap" v-loading="allLoading">
    <div class="main-wrap qm-content">
      <!-- 导航 -->
      <div class="order-navigation">
        <a
          class="text1"
          href="/#/catalogue"
        >{{L.home}}</a>
        <span class="line">/</span>
        <!-- 我的订单 -->
        <a
          class="text2"
          href="/#/selectRMAtype" 
        >{{L.myService}}</a>
      </div>
      <!-- 我的订单-标题 -->
      <div class="my-orders-title">
        <span class="text1">{{L.myService}}</span>
        <span class="text2" @click="goRMAList">{{L.viewTickets}}</span>
      </div>
      <div class="title">{{L.selectService}}</div>
      <div class="four-btn">

        <el-tooltip
          effect="dark"
          placement="top"
        >
        <div slot="content">{{L.commercialEnquiryMouse}}</div>
          <div class="square square1">
            <p class="square-title">{{L.commercialEnquiry}}</p>
            <div class="icon-wrap">
              <span class="iconfont icon1">&#xe665;</span>
            </div>
            <div class="btn" @click="goEnquiry('normal')">{{L.createEnquiry}}</div>
          </div>
        </el-tooltip>
        <!-- 三块cliam -->
        <template v-if="false">
          <!-- commercial claim -->
          <template v-if="false">
            <el-tooltip
              effect="dark"
              placement="top"
            >
            <div slot="content">A commercial / logistic incident could be raised if there is some issue with your delivery. For example you want to return something or you got damaged, wrong or less goods.</div>
              <div class="square square1">
                <p class="square-title">Commercial/Logistic Incident</p>
                <div class="icon-wrap">
                  <span class="iconfont icon1">&#xe640;</span>
                </div>
                <div class="btn" @click="goCommercial('normal')">Send Enquiry</div>
              </div>
            </el-tooltip>
          </template>

          <el-tooltip
            effect="dark"
            placement="top"
          >
          <div slot="content">A field/application incident is a problem or early failure that occurs after our products have been installed in a final application.</div>
            <div class="square square1">
              <p class="square-title">Field/Application Incident</p>
              <div class="icon-wrap">
                <span class="iconfont icon1">&#xe78d;</span>
              </div>
              <div class="btn" @click="goFieldFailuers('normal')">Create Claim</div>
            </div>
          </el-tooltip>

          <el-tooltip
            effect="dark"
            placement="top"
          >
          <div slot="content">A production incident is a problem or instant failure that occurs in your production, incoming inspection or during final testing.</div>
            <div class="square">
              <p class="square-title">Production Incident</p>
              <div class="icon-wrap">
                <span class="iconfont icon1">&#xe62d;</span>
              </div>
              <div class="btn" @click="goFailuresProd('normal')">Create Claim</div>
            </div>
          </el-tooltip>
        </template>
        
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:'MyRMA',
  data(){
    return {
      RMAtypeArr:[
        {value:'1',label:'Commercial/Logistic Incident'},
        {value:'2',label:'Field/Application Incident'},
        {value:'3',label:'Production Incident'}
      ],
      search:{
        dateFrom:'',
        dateTo:'',
        RMAno:'',
        customerName:'',
        RMAtype:''
      },
      orderList:[
        {
          id:'1',
          RMAno:'PR20230512-IT01',
          date:'21/02/2023',
          customerName:'Company Ltd.',
          customerContactName:'Paul Walker',
          customerContactEmail:'paul.walker@company.com',
          RMAtype:'Production Incident'
        },
        {
          id:'2',
          RMAno:'PR20230512-IT02',
          date:'23/02/2023',
          customerName:'Company Yhs.',
          customerContactName:'Jeck Walker',
          customerContactEmail:'jeck.walker@company.com',
          RMAtype:'Production Incident'
        },
        {
          id:'3',
          RMAno:'PR20230512-IT03',
          date:'25/02/2023',
          customerName:'Company Yhs.',
          customerContactName:'Terry Walker',
          customerContactEmail:'terry.walker@company.com',
          RMAtype:'Production Incident'
        }
      ] //列表数据
    }
  },
  methods:{
    goRMAList(){
      this.$router.push({
        path:'/rmaList'
      })
    },
    goDraftsRMAlist(){
      this.$router.push({
        path:'/draftsRMAlist'
      })
    }
  },
  mounted(){
    
  }
}
</script>

<style lang="less" scoped>
@import url('/public/css/public.less');
@import url('./index.less');
</style>

